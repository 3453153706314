/**
 * Program Timeline display for the About Page
 */

import React, { Component } from 'react'

import './index.scss'

import prototype from './img/prototype.svg'
import partnerships from './img/partnerships.svg'
import mediaexposure from './img/mediaexposure.svg'
import mentorships from './img/mentorships.svg'

class ProgramTimeline extends Component {
  render () {
    /*  <h1> A 3-month-long taste of entrepreneurship </h1>
        <p>
          Sint tempor laborum aliqua commodo adipisicing Lorem eiusmod ut nostrud. Commodo eiusmod culpa quis eu nisi consectetur reprehenderit velit laborum esse. Reprehenderit et elit ut id aliquip nisi amet labore eiusmod deserunt excepteur laboris sint ea. Dolor sit in amet dolor aliqua Lorem ex dolore.
        </p>
    */
    return (
      <div className='ProgramTimeline'>
        <h1>
          How can Innovation Labs help you?
        </h1>
        <br/>
        <div className='timeline'>
          <div className='element'>
            <img src={prototype} alt='' />
            <h2>
              Prototype
              <span>
                Innovation Labs invites young professionals to explore the potential of bleeding-edge technologies to solve real problems, for real people - by developing a Minimum Viable Product.
              </span>
            </h2>
          </div>
          <div className='element'>
            <img src={partnerships} alt='' />
            <h2>
              Partnerships
              <span>
                Program partners open for participants an arena for experimenting with dedicated IT&C solutions and infrastructures of the future. Explore and catch their interest with your insight and potential!
              </span>
            </h2>
          </div>
          <div className='element'>
            <img src={mediaexposure} alt='' />
            <h2>
              Media exposure
              <span>
                We guide teams in improving their graphic design, presentation skills and media footprint, shaping their media profile with distinctiveness and outreach.
              </span>
            </h2>
          </div>
          <div className='element'>
            <img src={mentorships} alt='' />
            <h2>
              Mentorships
              <span>
                Top mentors from the startup ecosystem and ICT industry keep open lines of dialogue and feedback throughout the program and beyond, helping teams to align disruptive technologies with well-specified needs.
              </span>
            </h2>
          </div>
        </div>
      </div>
    )
  }
}

export default ProgramTimeline
