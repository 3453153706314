import React, { Component } from "react";

import Boost from "./assets/Boost.svg";
import Guide from "./assets/Guide.svg";
// import Note from './assets/Note.svg'
import Star from "./assets/Star.svg";
// import Time from './assets/Time.svg'
import Wrench from "./assets/Wrench.svg";

import { Link } from "react-router-dom";

import "./index.scss";
// import RegisterButton from '../../Common/RegisterButton'

const icons = [
  // {
  //   title: ['Pre-recruiting'],
  //   subtitle: 'December - January',
  //   image: Note,
  //   type : "small-link-register",
  //   link : "register",
  //   text : "Pre-register now"
  // },
  {
    title: ["Registration"],
    subtitle: "December-February",
    image: Wrench,
    type: "small",
  },
  {
    title: ["4 Selection", "Hackathons"],
    subtitle: "March",
    image: Boost,
    type: "small",
  },
  {
    title: [
      "12 Workshops",
      "24 ILabs Unplugged Sessions",
      "5 Pitch Sessions",
      "Progress Follow-up",
    ],
    subtitle: "March - May",
    image: Guide,
    type: "small",
  },
  // {
  //   title1: ["12 Workshops", "24 TechTalks"],
  //   title2: ["5 Pitch Sessions", "Progress Follow-up"],

  //   subtitle: 'March - May',
  //   image1: Guide,
  //   image2: Time,

  //   type : "large"

  // },

  {
    title: ["Demo Day"],
    subtitle: "28 May",
    image: Star,
    type: "small-link",
    link: "https://www.facebook.com/ILabsRo/videos/439602534553757",
    text: "DemoDay 2023",
  },
  {
    title: ["Alumni Support"],
    subtitle: "",
    image: Star,
    type: "small",
  },
  // {
  //   title: 'Long-term support',
  //   subtitle: 'May and beyond',
  //   image: Time
  // }
];

class SmallTimeline extends Component {
  render() {
    return (
      <div className="SmallTimeline">
        <h1>2024 Program structure</h1>
        <div className="items">
          {icons.map((element) => {
            if (element.type === "large") {
              let title1 = element.title1.map((title) => {
                return <h1> {title} </h1>;
              });
              let title2 = element.title2.map((title) => {
                return <h1> {title} </h1>;
              });
              return (
                <div className="composed-layout">
                  <div className="composed-item">
                    <div className="item">
                      <img src={element.image1} alt="" />
                      {title1}
                    </div>
                    <div className="item">
                      <img src={element.image2} alt="" />
                      {title2}
                    </div>
                  </div>
                  <h2>{element.subtitle}</h2>
                </div>
              );
            }
            let titles = element.title.map((title) => {
              return <h1> {title} </h1>;
            });
            return (
              <div className="item">
                <img src={element.image} alt="" />
                {titles}
                <h2>{element.subtitle}</h2>
                {element.type === "small-link" ? (
                  <a target="_blank" href={element.link}>
                    <button>{element.text} </button>
                  </a>
                ) : null}
                {/* {element.type === "small-link-register"?<RegisterButton  />:null} */}
              </div>
            );
          })}
        </div>
        {/* <div className="benefitsSection"> */}
        {/* <div className = "benefitsColumn">
            <p> 1:1 mentorship session </p>
          </div> */}
        {/* <div className = "benefitsColumn">
            <p> Media Exposure </p>
            <p> Access to <br/> customers </p>
          </div> */}
        {/* <div className = "benefitsColumn">
            <p> University Support </p>
            <p> Access to decisions <br/> factors </p>

          </div> */}
        {/* <div className = "benefitsColumn">
          <p> Access to Technologies </p>
        </div> */}

        {/* </div> */}
        <div className="buttonContainer">
          <Link to="/program">
            <button>learn more</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default SmallTimeline;
