/**
 * Teams listing with category filtering
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Paginator from "react-paginate";

import clock from "./img/clock.svg";
import location from "./img/location.svg";
import folder from "./img/folder.svg";

import TeamBox from "components/Common/TeamBox";

import "./index.scss";

function unique(list) {
  return list.filter((x, i) => list.indexOf(x) === i);
}

class Listing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      city: "All",
      track: "All",
      year: "All",
      currentPage: 0,
    };

    this.setFilter = this.setFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    if (this.props.city && this.props.year) {
      this.setState({ city: this.props.city, year: this.props.year });
    }

    if (this.props.track) {
      this.setState({ track: this.props.track });
    }
  }

  setFilter(e) {
    let value = e.currentTarget.textContent.trim();
    let category = e.currentTarget.dataset.category;
    let state = { ...this.state };
    state[category] = value.trim();
    this.setState(state);
    window.history &&
      window.history.pushState(
        null,
        "",
        `/teams/${state.year}/${state.city}/${state.track}/`,
      );
  }

  getFilter(key) {
    let { teams } = this.props;
    let values = unique(
      teams.filter((x) => x[key]).map((x) => x[key].trim()),
    ).filter((x) => x.trim() !== "");

    if (key !== "year") {
      values = values.sort();
    }

    let isActive = (val, key) => (this.state[key] === val ? "active" : "");

    /**
     * Gets tracks from all teams in matching year
     * @param {int} year Year from which you want to extract tracks
     */
    const getYearTracks = () =>
      unique(
        teams.filter((t) => t.year === this.state.year).map((t) => t.track),
      );

    console.log("year tracks", key, getYearTracks(), this.state.year);

    return (
      <ul>
        <li
          key={0}
          className={isActive("All", key)}
          onClick={this.setFilter}
          data-category={key}
        >
          All
        </li>
        {values
          .filter((v) => v.trim() !== ".")
          // Extract only tracks that match current year
          .filter((v) =>
            key === "track" && this.state.year !== "All"
              ? getYearTracks().includes(v)
              : true,
          )
          .map((x, i) => (
            <li
              data-category={key}
              className={isActive(x, key)}
              key={i + 1}
              onClick={this.setFilter}
            >
              {x}
            </li>
          ))}
      </ul>
    );
  }

  handlePageChange(val) {
    let { selected } = val;
    if (selected >= 0) {
      this.setState({ ...this.state, currentPage: selected });
    }
  }

  getTeams() {
    let { teams } = this.props;
    let { city, year, track, currentPage } = this.state;

    if (teams.length === 0) {
      return <img src="/loader-icon.svg" className="loader" alt="" />;
    } else {
      if (currentPage < 0) {
        currentPage = 0;
      }

      let pageCount;
      let teamsOnPage = 10;
      let filtered = teams
        .filter((x) => x.city && x.year && x.track)
        .filter((x) => (city === "All" ? true : x.city.trim() === city))
        .filter((x) => (year === "All" ? true : x.year.trim() === year))
        .filter((x) => (track === "All" ? true : x.track.trim() === track));

      pageCount = filtered.length / teamsOnPage;
      filtered = filtered
        .slice(
          teamsOnPage * currentPage,
          teamsOnPage * currentPage + teamsOnPage,
        )
        .map((x, i) => (
          <Link key={i + x.name} to={"/teams/" + x.name}>
            <TeamBox data={x} />
          </Link>
        ));

      if (filtered.length > 0) {
        window.scrollBy(0, -5000);
        return [
          <div key={0}>{filtered}</div>,
          <Paginator
            key={1}
            previousLabel="<"
            nextLabel=">"
            containerClassName="paginator"
            activeClassName="active"
            disabledClassName="disabled"
            onPageChange={this.handlePageChange}
            pageCount={pageCount}
            pageRangeDisplayed={10}
          />,
        ];
      } else {
        return <h2 className="empty"> No matching teams found </h2>;
      }
    }
  }

  render() {
    if (this.props.teams.length === 0) {
      return null;
    }

    return (
      <div className="Listing">
        <header>
          <div className="title">
            <h1> See all our amazing teams! </h1>
            <p>
              Go through the 10 year history of Innovation Labs and look at some
              of the best creative minds and ideas solving the most complicated
              problems in various domains!
            </p>
          </div>

          <div className="filters">
            <div>
              <img src={clock} alt="" />
              {this.getFilter("year")}
            </div>
            <div>
              <img src={location} alt="" />
              {this.getFilter("city")}
            </div>
            <div>
              <img src={folder} alt="" />
              {this.getFilter("track", this.state.year)}
            </div>
          </div>
        </header>
        <div className="list">{this.getTeams()}</div>
      </div>
    );
  }
}

Listing.propTypes = {
  teams: PropTypes.array,
};

Listing.defaultProps = {
  teams: [],
};

export default Listing;
