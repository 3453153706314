import React from 'react'
import './index.scss'

class TeamsShowcase2 extends React.Component{

    state = {
        highlights : [
            {
                title: "XVision, thas uses Machine Vision to assist radiologists in analyzing chest x-rays in over 30 hospitals, has just raised a 1M€ round lead by GapMinder",
                link : "https://business-review.eu/tech/xvision-gets-e-1-million-in-a-new-round-of-funding-226621"
            },
            {
                title: "OxidOS (https://oxidos.io), a deep-tech automotive start-up, has attracted a 1.2M€ investment from Early Game Ventures",
                link : "https://therecursive.com/romanian-oxidos-raises-over-e1m-to-get-safer-cars-out-on-the-road/"
            },
            {
                title: "Coda Intelligence (https://www.codaintelligence.com/), a cybersecurity start-up, has attracted an 800.000€ investment from Early Game Ventures and ROCA X. ",
                link : "https://www.zf.ro/business-hi-tech/coda-intelligence-un-start-up-de-tehnologie-din-zona-de-cyber-19287664"
            },
            {
                title: "Soleadify (https://www.soleadify.com/), an ML start-up focused on digital marketing, has secured a 1.5M€ investment from GapMinder and Seedblink. ",
                link : "https://www.profit.ro/povesti-cu-profit/it-c/startup-ul-romanesc-soleadify-a-atras-o-investitie-de-1-5-milioane-de-dolari-19521122"
            },
            {
                title: "PentestTools (https://pentest-tools.com/) has seen rapid team growth in 2022, and are still expanding their team with multiple open positions. ",
                link : "https://fellowship.startglobal.org/"
            },
            {
                title: "VatisTech (https://vatis.tech/ro-RO) - an audio-video processing platform, is preparing a new round of investments for a global expansion.",
                link : "https://www.zf.ro/business-hi-tech/start-up-ul-vatis-tech-platforma-pentru-procesarea-continutului-20898708 "
            }

        ]
    }
    render () {
    
        let highlightsList = this.state.highlights.map( (e, index)=> {

            // //id = "last-box" 
            if ( index === 5){
                return <div className = "big-box"> 
                    <p> {e.title} <span> <a target = "_blank" className = "more-details" href = {e.link}> More Details </a> </span> </p>
                </div>
            }
            return (
                <div className = "big-box">
                    <p> {e.title} <span> <a target = "_blank" className = "more-details" href = {e.link}> More Details </a> </span> </p>
                </div>
            )
        })
        return (
          <div className='TeamsShowcase2'>
            <h2> Latest Alumni Highlights </h2>
            <div className='list-big'>
            {highlightsList}
            </div>
          </div>
        )
      }
    }

export default TeamsShowcase2;


